import { AssistDefinitionCategoryEnum } from "../../../api/assist-definition/types";
import { TSelectOption } from "../select/types";
import { FilterEnum, TActiveFilterTag, TFilterConfig, TFilterConfigDropdown } from "./types";

export const ENHANCED_WORKFLOW_CATEGORIES: AssistDefinitionCategoryEnum[] = [
  AssistDefinitionCategoryEnum.SCHEDULED_NOTIFICATION,
  AssistDefinitionCategoryEnum.OPPORTUNITY_NOTIFICATION,
  AssistDefinitionCategoryEnum.SCHEDULED_REPORT,
  AssistDefinitionCategoryEnum.AI_CALL_NOTIFICATION,
  AssistDefinitionCategoryEnum.AI_SIGNAL,
  AssistDefinitionCategoryEnum.AI_NOTIFICATION,
  AssistDefinitionCategoryEnum.CONTACT_AUTOMATION,
  AssistDefinitionCategoryEnum.EXECUTIVE_BRIEF,
  AssistDefinitionCategoryEnum.AUTO_CREATE_ROOMS,
  AssistDefinitionCategoryEnum.DEFAULT,
];

export function workflowsInitialFilterConfig(category: AssistDefinitionCategoryEnum): TFilterConfig {
  const allDropdowns: TFilterConfigDropdown[] = [
    {
      label: "Trigger",
      options: [],
      selectedOptions: [],
      type: FilterEnum.TRIGGER,
    },
    {
      label: "Object",
      options: [],
      selectedOptions: [],
      type: FilterEnum.FLOW_OBJECT,
    },
    {
      label: "Status",
      options: [],
      selectedOptions: [],
      type: FilterEnum.STATUS,
    },
    {
      label: "Created By",
      options: [],
      selectedOptions: [],
      type: FilterEnum.CREATED_BY,
    },
    {
      label: "Tags",
      options: [],
      selectedOptions: [],
      type: FilterEnum.TAGS,
    },
  ];

  const filteredDropdowns: TFilterConfigDropdown[] = allDropdowns.filter((dropdown) => {
    if (dropdown.type === FilterEnum.TAGS) {
      return ENHANCED_WORKFLOW_CATEGORIES.includes(category);
    }

    if (dropdown.type === FilterEnum.FLOW_OBJECT) {
      return [AssistDefinitionCategoryEnum.OPPORTUNITY_NOTIFICATION, AssistDefinitionCategoryEnum.DEFAULT].includes(
        category
      );
    }

    return true;
  });

  const filterConfig: TFilterConfig = {
    search: {
      label: "Search",
      value: "",
      type: FilterEnum.SEARCH,
    },
    dropdowns: filteredDropdowns,
  };

  return filterConfig;
}

export function updateDropdownOptionsInFilterConfig(
  type: string,
  options: TSelectOption[],
  filterConfig: TFilterConfig
): TFilterConfig {
  const updatedConfig: TFilterConfig = { ...filterConfig };
  const dropdown = updatedConfig.dropdowns?.find((dropdown) => dropdown.type === type);
  if (dropdown) {
    dropdown.options = options;
  }
  return updatedConfig;
}

//This function is used to update the filter config with the filter tags we receive from store
export function updateConfigWithFilterTags(filterTags: TActiveFilterTag[], filterConfig: TFilterConfig): TFilterConfig {
  const updatedConfig: TFilterConfig = { ...filterConfig };
  filterTags.forEach((tag) => {
    switch (tag.type) {
      case FilterEnum.SEARCH:
        if (filterConfig.search) {
          filterConfig.search.value = tag.value as string;
        }
        break;
      case FilterEnum.SEARCH_ID:
        if (filterConfig.searchId) {
          filterConfig.searchId.value = tag.value as number;
        }
        break;
      case FilterEnum.DATE:
        if (filterConfig.datePicker) {
          filterConfig.datePicker.value = tag.value as [number, number];
        }
        break;
      default: {
        const dropdown = filterConfig.dropdowns?.find((d) => d.type === tag.type);
        if (dropdown) {
          dropdown.selectedOptions.push({
            label: tag.displayValue.toString(),
            value: tag.value as string,
            momentumUserId: tag.momentumUserId ?? undefined,
          });
        }
        break;
      }
    }
  });
  return updatedConfig;
}
