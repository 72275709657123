import { createAuthGuard } from "@auth0/auth0-vue";
import { App } from "vue";
import { RouteRecordRaw } from "vue-router";

export function createUserAppRoutes(app: App): RouteRecordRaw[] {
  const authGuard = createAuthGuard(app);

  return [
    {
      path: "/meetings",
      name: "meetings",
      component: () => import("../pages/user-dashboard/MeetingsView.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Meetings",
        layout: "UserDashboard",
      },
    },
    {
      path: "/meeting/:id",
      name: "meeting",
      component: () => import("../pages/user-dashboard/meeting-details/MeetingEdit.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Meetings",
        layout: "UserDashboard",
      },
      children: [
        {
          path: "ask-momentum",
          name: "meeting-ask-momentum",
          component: () => import("../pages/user-dashboard/meeting-details/AskMomentum.vue"),
          beforeEnter: authGuard,
          meta: {
            title: "Ask Momentum",
            layout: "UserDashboard",
          },
        },
        {
          path: "data-insights",
          name: "meeting-data-insights",
          component: () => import("../pages/user-dashboard/meeting-details/DataInsights.vue"),
          meta: {
            title: "Data Insights",
            layout: "UserDashboard",
          },
        },
        {
          path: "meeting-info",
          name: "meeting-additional-info",
          component: () => import("../pages/user-dashboard/meeting-details/AdditionalInfo.vue"),
          beforeEnter: authGuard,
          meta: {
            title: "Meeting Info",
            layout: "UserDashboard",
          },
        },
        {
          path: "action-items",
          name: "meeting-action-items",
          component: () => import("../pages/user-dashboard/meeting-details/ActionItems.vue"),
          beforeEnter: authGuard,
          meta: {
            title: "Action Items",
            layout: "UserDashboard",
          },
        },
        {
          path: "send-email",
          name: "meeting-send-email",
          component: () => import("../pages/user-dashboard/meeting-details/SendEmail.vue"),
          beforeEnter: authGuard,
          meta: {
            title: "Send Email",
            layout: "UserDashboard",
          },
        },
        {
          path: "coaching",
          name: "meeting-coaching",
          component: () => import("../pages/user-dashboard/meeting-details/CoachingCompetency.vue"),
          beforeEnter: authGuard,
          meta: {
            title: "Coaching",
            layout: "UserDashboard",
          },
        },
      ],
    },
    {
      path: "/call-library/:tab?",
      name: "user-call-library",
      component: () => import("../pages/user-dashboard/UserCallLibrary.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Call Library",
        layout: "UserDashboard",
      },
    },
    {
      path: "/call-library/playlists/:id",
      name: "user-playlist-details",
      component: () => import("../pages/user-dashboard/UserPlaylistDetails.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Playlist Details",
        layout: "UserDashboard",
      },
    },
    {
      path: "/autopilot",
      name: "autopilot",
      component: () => import("../pages/user-dashboard/AutoPilot.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Autopilot",
        layout: "UserDashboard",
      },
    },
    {
      path: "/email-follow-up",
      name: "email-follow-up",
      component: () => import("../pages/user-dashboard/EmailFollowUpView.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Email Follow-Up",
        layout: "UserDashboard",
      },
    },
    {
      path: "/email-follow-up/config",
      name: "user-email-follow-up-config",
      component: () => import("../pages/user-dashboard/EmailFollowUpUserConfig.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Email Follow-Up Config",
        layout: "UserDashboard",
      },
    },
    {
      path: "/zoom-settings",
      name: "zoom-settings",
      component: () => import("../pages/user-dashboard/ZoomUserSettings.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Settings Zoom",
        layout: "UserDashboard",
      },
    },
    {
      path: "/individual-account-connect",
      name: "individual-account-connect",
      component: () => import("../pages/MyIntegrations.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Individual Account Connect",
        layout: "UserDashboard",
      },
    },
  ];
}
