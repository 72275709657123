<template>
  <NDropdown :options="state.options" trigger="click" placement="top-start" @select="handleSelect">
    <button type="button" class="inline-block text-sm rounded-full focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      <div class="flex items-center">
        <MProfileImage :name="authUser && profile?.name" :image-src="authUser?.picture" :alt-text="`User's Avatar`" :custom-class="`h-8 w-8 rounded-full`" />
        <p class="ml-2" v-text="authUser && profile?.name" />
      </div>
    </button>
  </NDropdown>
</template>

<script lang="ts">
import { defineComponent, reactive, onMounted, computed, watch } from "vue";
import { useAuth0, Auth0VueClient } from "@auth0/auth0-vue";
import { Store, useStore } from "vuex";
import { useRouter } from "vue-router";
import { NDropdown } from "naive-ui";
import { useFeaturesStore } from "../../store/features";
import { getEnabledFeature } from "../../launch-darkly/get-enabled-feature";
import { isExactRoleUserAdmin } from "../../utils/roles";
import MProfileImage from "../reusable-components/MProfileImage.vue";

export default defineComponent({
  components: {
    NDropdown,
    MProfileImage,
  },
  setup() {
    const { logout }: Auth0VueClient = useAuth0();
    const store: Store<any> = useStore();
    const featuresStore = useFeaturesStore();
    const router: any = useRouter();
    const state: any = reactive({
      showUserDetails: <boolean>false,
      options: [
        {
          label: "Sign Out",
          key: "sign-out",
        },
      ],
      featureToggles: {},
    });

    onMounted(async () => {
      setSupportUserIfApplicable();
      state.featureToggles["ui.notes.v1.beta"] = await getEnabledFeature("ui.notes.v1.beta", authUser?.value);
    });

    const authUser = computed(() => {
      return store.state.authUser;
    });

    const profile = computed(() => {
      return store.state.profile;
    });

    watch(
      () => state.featureToggles,
      (newValue) => {
        if (newValue["ui.notes.v1.beta"] && !isExactRoleUserAdmin()) {
          state.options.unshift({
            label: "Momentum Home",
            key: featuresStore.state.features["Playlists_Enabled"] ? "user-call-library" : "meetings",
          });
        }
      },
      {
        deep: true,
      }
    );

    watch(
      () => featuresStore.state.features["Playlists_Enabled"],
      (newValue) => {
        if (newValue) {
          const momentumHomeOption = state.options.find((option: any) => option.key === "meetings");
          if (momentumHomeOption) {
            momentumHomeOption.key = "user-call-library";
          }
        }
      }
    );

    async function logoutAuthUser(): Promise<void> {
      await logout({ logoutParams: { returnTo: window.location.origin + "/sign-in" } });
    }

    function handleSelect(key: string): void {
      if (["user-call-library", "meetings"].includes(key)) {
        router.push({ name: key });
      } else if (key === "sign-out") {
        logoutAuthUser();
      }

      return null;
    }

    function setSupportUserIfApplicable(): void {
      if (authUser?.value?.isMomentumSupport && profile?.value?.email) {
        state.options.unshift({
          label: `Impersonating: ${profile.value.email}`,
          key: "impersonating-user-email",
          disabled: true,
        });
      }
    }

    return {
      state,
      handleSelect,
      authUser,
      profile,
    };
  },
});
</script>

<style scoped>
.user-buttons {
  @apply block w-full text-left px-4 py-2 text-sm text-gray-700;
}

.user-buttons:hover {
  @apply text-gray-900 bg-gray-100;
}

.user-buttons:focus {
  @apply outline-none bg-gray-100 text-gray-900;
}
</style>
