import gql from "graphql-tag";

export const organizationIntegrationQuery = gql`
  query GetOrganizationIntegrations {
    user_integration {
      service
      status
      useForOrganization
      updatedAt
      user {
        email
      }
    }
  }
`;

export const slackWorkspaceQuery = gql`
  query GetSlackWorkspace {
    slack_workspace {
      teamID
      updatedAt
    }
  }
`;

export const organizationIntegrationIsAuthenticatedQuery = gql`
  query ServiceIsConnectedForOrganization($service: String!) {
    user_integration(
      where: { useForOrganization: { _eq: true }, service: { _eq: $service }, status: { _eq: "AUTHENTICATED" } }
    ) {
      status
    }
  }
`;
export const userIntegrationsQuery = gql`
  query GetUserIntegrations($userId: Int!) {
    user_integration(where: { userId: { _eq: $userId } }) {
      service
      status
      useForOrganization
      updatedAt
    }
  }
`;
