import { createAuthGuard } from "@auth0/auth0-vue";
import { App } from "vue";
import { RouteRecordRaw } from "vue-router";

export function createSupportRoutes(app: App): RouteRecordRaw[] {
  const authGuard = createAuthGuard(app);
  return [
    {
      path: "/momentum-support",
      name: "momentum-support-home",
      component: () => import("../pages/MomentumSupportHome.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Support Home",
        layout: "GlobalMomentumSupport",
      },
    },
    {
      path: "/momentum-support/entity/search",
      name: "momentum-support-entity-search",
      component: () => import("../pages/RedirectToEntity.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Entity Search",
        layout: "GlobalMomentumSupport",
      },
    },
    {
      path: "/momentum-support/entity/search/redirect-to-entity/:entityName/:entityId",
      name: "momentum-support-redirect-to-entity",
      component: () => import("../pages/RedirectToEntity.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Entity Search",
        layout: "GlobalMomentumSupport",
      },
    },

    //global execution logs
    {
      path: "/momentum-support/execution-logs",
      name: "momentum-support-global-execution-logs",
      component: () => import("../pages/GlobalExecutionLogs.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Global Execution Logs",
        layout: "GlobalMomentumSupport",
      },
      children: [
        {
          path: "",
          name: "momentum-support-global-execution-logs-workflows",
          component: () => import("../pages/GlobalWorkflowExecutions.vue"),
          beforeEnter: authGuard,
          meta: {
            title: "Workflow Logs",
          },
        },
        {
          path: "meetings",
          name: "momentum-support-global-execution-logs-meetings",
          component: () => import("../pages/GlobalMeetingExecutions.vue"),
          beforeEnter: authGuard,
          meta: {
            title: "Meeting Logs",
          },
        },
      ],
    },
    {
      path: "/momentum-support/organization/:organizationId",
      name: "momentum-support-organization",
      component: () => import("../pages/MomentumSupportOrganization.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Users",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/service-accounts",
      name: "momentum-support-organization-service-accounts",
      component: () => import("../pages/MomentumSupportOrganizationServiceAccounts.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Service Accounts",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/assist-definitions",
      name: "momentum-support-organization-assist-definitions",
      component: () => import("../pages/MomentumSupportOrganizationAssistDefinitions.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization Assist Definitions",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/assist-definition/:assistDefinitionId",
      name: "momentum-support-organization-assist-definition",
      component: () => import("../pages/MomentumSupportOrganizationAssistDefinition.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization Assist Definition",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/assist-definition/:assistDefinitionId?/assists",
      name: "momentum-support-organization-assists",
      component: () => import("../pages/OrganizationAssists.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Assists",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/assist-definition/:assistDefinitionId/assist/:assistId",
      name: "momentum-support-organization-assist",
      component: () => import("../pages/OrganizationAssist.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Assist",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/assist-definition/:assistDefinitionId/assist/:assistId/actions",
      name: "momentum-support-organization-assist-actions",
      component: () => import("../pages/OrganizationAssistActions.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Assist Actions",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/assist-definition/:assistDefinitionId/assist/:assistId/action/:assistActionId",
      name: "momentum-support-organization-assist-action",
      component: () => import("../pages/OrganizationAssistAction.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Assist Action",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/salesforce-metadata-objects",
      name: "momentum-support-organization-salesforce-metadata-objects",
      component: () => import("../pages/OrganizationSalesforceMetadataObjects.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Salesforce Metadata Objects",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/salesforce-metadata-object/:salesforceMetadataObjectName",
      name: "momentum-support-organization-salesforce-metadata-object",
      component: () => import("../pages/OrganizationSalesforceMetadataObject.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Salesforce Metadata Object",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/salesforce-metadata-object/:salesforceMetadataObjectName/field/:salesforceMetadataFieldName",
      name: "momentum-support-organization-salesforce-metadata-field",
      component: () => import("../pages/OrganizationSalesforceMetadataField.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Salesforce Metadata Field",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/digest-definitions",
      name: "momentum-support-organization-digest-definitions",
      component: () => import("../pages/MomentumSupportOrganizationDigestDefinitions.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization Digest Definitions",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/digest-definition/:digestDefinitionId",
      name: "momentum-support-organization-digest-definition",
      component: () => import("../pages/MomentumSupportOrganizationDigestDefinition.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization Digest Definition",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/digest-definition/:digestDefinitionId/digest-instance/:digestInstanceId",
      name: "momentum-support-organization-digest-instance",
      component: () => import("../pages/OrganizationDigestInstance.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization Digest Instance",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/extraction-raw-sets",
      name: "momentum-support-organization-extraction-raw-sets",
      component: () => import("../pages/OrganizationExtractionRawSets.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization Extraction Raw Sets",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/extraction-raw-set/:extractionRawSetId",
      name: "momentum-support-organization-extraction-raw-set",
      component: () => import("../pages/OrganizationExtractionRawSet.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization Extraction Raw Set",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/extraction-raw-set/:extractionRawSetId/raw/:extractionRawId",
      name: "momentum-support-organization-extraction-raw",
      component: () => import("../pages/OrganizationExtractionRaw.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization Extraction Raw",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/meetings",
      name: "momentum-support-organization-meetings",
      component: () => import("../pages/MomentumSupportOrganizationMeetings.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization Meetings",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/meeting/:meetingId",
      name: "momentum-support-organization-meeting",
      component: () => import("../pages/MomentumSupportOrganizationMeeting.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization Meeting",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/meeting/:meetingId/zoom/events",
      name: "momentum-support-organization-meeting-zoom-events",
      component: () => import("../pages/OrganizationMeetingZoomEvents.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization Meeting Zoom Events",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/meeting/:meetingId/zoom/event/:zoomEventId",
      name: "momentum-support-organization-meeting-zoom-event",
      component: () => import("../pages/OrganizationMeetingZoomEvent.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization Meeting Zoom Event",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/meeting/:meetingId/ai-tasks",
      name: "momentum-support-organization-meeting-ai-tasks",
      component: () => import("../pages/MomentumSupportOrganizationMeetingAiTasks.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization Meeting AI Tasks",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/logs",
      name: "momentum-support-organization-logs",
      component: () => import("../pages/MomentumSupportOrganizationLogs.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "View Organization Logs",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/ai-tasks",
      name: "momentum-support-organization-ai-tasks",
      component: () => import("../pages/MomentumSupportOrganizationAiTasks.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization AI Tasks",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/ai-task/:aiTaskId",
      name: "momentum-support-organization-ai-task",
      component: () => import("../pages/MomentumSupportOrganizationAiTask.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization AI Task",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/ai-llm-request/:aiLlmRequestId",
      name: "momentum-support-organization-ai-llm-request",
      component: () => import("../pages/MomentumSupportOrganizationAiLlmRequest.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization AI LLM Request",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/ai-embedding-search/:aiEmbeddingSearchId",
      name: "momentum-support-organization-ai-embedding-search",
      component: () => import("../pages/MomentumSupportOrganizationAiEmbeddingSearch.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization AI Embedding Search",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/settings",
      name: "momentum-support-organization-settings",
      component: () => import("../pages/MomentumSupportOrganizationAttributes.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization Settings",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/user/:userId",
      name: "momentum-support-organization-user",
      component: () => import("../pages/MomentumSupportOrganizationUser.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization User",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/user/:userId/auth0-logs",
      name: "momentum-support-organization-user-auth0-logs",
      component: () => import("../pages/MomentumSupportOrganizationUserAuth0Logs.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization User Auth0 Logs",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/feature-flags",
      name: "momentum-support-organization-feature-flags",
      component: () => import("../pages/FeatureFlags.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Feature Flags",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/feed-payloads",
      name: "momentum-support-organization-feed-payloads",
      component: () => import("../pages/FeedPayloads.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Feed Payloads",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/feed-payload/:feedPayloadId",
      name: "momentum-support-organization-feed-payload",
      component: () => import("../pages/FeedPayload.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Feed Payload",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/assist-trigger-payloads",
      name: "momentum-support-organization-assist-trigger-payloads",
      component: () => import("../pages/AssistTriggerPayloads.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Assist Trigger Payloads",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/assist-trigger-payload/:assistTriggerPayloadId",
      name: "momentum-support-organization-assist-trigger-payload",
      component: () => import("../pages/AssistTriggerPayload.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Assist Trigger Payload",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/action-runners",
      name: "momentum-support-organization-action-runners",
      component: () => import("../pages/ActionRunners.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Action Runners",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/coaching-aggregates",
      name: "momentum-support-organization-coaching-aggregates",
      component: () => import("../pages/OrganizationCoachingAggregates.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Coaching Aggregates",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/coaching-aggregate/:coachingAggregateInstanceId",
      name: "momentum-support-organization-coaching-aggregate",
      component: () => import("../pages/OrganizationCoachingAggregate.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Coaching Aggregate",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/workflow-runs",
      name: "momentum-support-organization-workflow-runs",
      component: () => import("../pages/WorkflowRuns.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Workflow Runs",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/domains",
      name: "momentum-support-organization-domains",
      component: () => import("../pages/OrganizationDomains.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Domains",
        layout: "MomentumSupport",
      },
    },
  ];
}
