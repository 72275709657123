/* eslint-disable no-unused-vars */
import { TSelectOption } from "../select/types";

export enum FilterEnum {
  //Organizations
  ORGANIZATIONS = "ORGANIZATIONS",

  // Teams
  SALESFORCE_ROLE = "SALESFORCE_ROLE",
  ROLE = "ROLE",
  INTEGRATION = "INTEGRATION",
  AI_LICENSE = "AI_LICENSE",
  AI_LICENSE_SETTINGS = "AI_LICENSE_SETTINGS",

  // Autopilot Extraction
  OBJECT = "OBJECT",
  STATUS = "STATUS",

  // Execution Pipeline
  SEARCH_ID = "SEARCH_ID",
  WORKFLOW = "WORKFLOW",

  // Workflows
  SEARCH = "SEARCH",
  TRIGGER = "TRIGGER",
  FLOW_OBJECT = "FLOW_OBJECT",
  CREATED_BY = "CREATED_BY",
  CATEGORY = "CATEGORY",
  TAGS = "TAGS",

  // DealRooms
  IS_ARCHIVED = "IS_ARCHIVED",

  // Call Library
  ACCOUNT = "ACCOUNT",
  DATE = "DATE",
  HOST = "HOST",
  PARTICIPANT = "PARTICIPANT",
  PLAYLIST = "PLAYLIST",
}

export type TFilterConfig = {
  search?: TFilterConfigSearch;
  searchId?: TFilterConfigSearchId;
  dropdowns?: TFilterConfigDropdown[];
  datePicker?: TFilterConfigDatePicker;
  disabled?: boolean;
};

export type TFilterConfigDatePicker = {
  label: string;
  pickerType: "date" | "daterange";
  type: FilterEnum;
  value: number | [number, number];
};

export type TFilterConfigDropdown = {
  label: string;
  options: TSelectOption[];
  showOnlyCurrentSelection?: boolean;
  type: FilterEnum;
  selectedOptions: TSelectOption[];
};

export type TFilterConfigSearch = {
  label: string;
  type: FilterEnum;
  value: string;
};

export type TFilterConfigSearchId = {
  label: string;
  type: FilterEnum;
  value: number;
};

export type TActiveFilterTag = {
  type: FilterEnum;
  label: string;
  value: string | number | [number, number] | boolean;
  displayValue: string | number;
  exclude?: boolean;
  momentumUserId?: number;
};
