import { watch } from "vue";
import { useConfirmActionStore } from "./store";

export function confirmAction(message: string, options?: { description: string }): Promise<boolean> {
  const { state, setConfirmingAction } = useConfirmActionStore();
  return new Promise((resolve) => {
    setConfirmingAction(message, options);
    watch(
      () => state.confirmingAction,
      (confirmingAction) => {
        if (!confirmingAction) {
          resolve(state.isConfirmed);
        }
      }
    );
  });
}
